import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Web Solution" img="web_solution.jpg" text="Create user-friendly websites to enhance engagement and deliver exceptional online experiences for your business." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="App Solution" img="app_solution.jpg" text="Develop innovative, user-centric mobile and web applications to boost engagement and streamline your business operations." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="AI Solution" img="ai_solution.jpg" text="Implement advanced AI technologies to automate processes, enhance decision-making, and drive business innovation." />
                    </div>
                </div>
            </div>
        </div>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Digital Transformation" img="digital_transformation.jpeg" text="Modernize your business with cutting-edge technologies, streamline processes, and drive growth in the digital age." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Intelligence Transformation" img="intelligence_transformation.jpg" text="Leverage advanced analytics and AI to gain actionable insights, improve decision-making, and boost business performance." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="AGI" img="agi.jpg" text="Revolutionize operations with AGI: automate complex tasks, enhance decision-making, and drive innovation across your business." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
