function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>
      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src={`${process.env.PUBLIC_URL}/about_us.jpg`} className="img-fluid" style={{ width: '100%', height: '100%' }}/>
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
            We specialize in digital transformation solutions to usher corporations into the digital era. Our intelligence transformation services turn digitized data into actionable insights, and we aim to build AGI to help businesses grow and innovate continuously.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
