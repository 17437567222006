function Header() {
  return (
    <header>
      <video src={`${process.env.PUBLIC_URL}/home.mp4`} loop autoPlay muted></video>
      <h1>Innovation | Creation | Love</h1>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
